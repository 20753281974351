import supportedCurrency from 'src/assets/emoney/tokens/currencies';
import type { Currency, CurrencyCode } from 'types/emoney/Token';

/**
 * Currency helper functions - Get Currency by code
 * @param code currency code
 * @param {Array} sc supported currencies
 * @returns {Array} supported currencies, filtered by currency code.
 */
const get = (
  code: Uppercase<CurrencyCode> | Lowercase<CurrencyCode> | undefined,
  sc = supportedCurrency(),
): Currency | undefined => {
  if (code && Array.isArray(sc)) {
    return sc?.find(
      (s: Currency) => s.code.toLowerCase() === code.toLowerCase(),
    );
  }
  return undefined;
};

const currencySortOrder: CurrencyCode[] = ['eur', 'gbp', 'usd', 'isk'];

// eslint-disable-next-line import/prefer-default-export
export { get, currencySortOrder };
