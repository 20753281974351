// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdDzT{max-height:var(--size);max-width:var(--size);position:relative}.mdDzT .GbPgE{height:var(--size);width:var(--size)}.mdDzT .b42r6{height:calc(var(--size)*.6);position:absolute;right:-24%;top:48%;width:calc(var(--size)*.6)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `mdDzT`,
	"token": `GbPgE`,
	"chain": `b42r6`
};
module.exports = ___CSS_LOADER_EXPORT___;
