// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kZC0Y{border:none;-webkit-box-sizing:border-box;box-sizing:border-box;color:rgba(0,0,0,.87);cursor:default;display:-ms-inline-flexbox;display:inline-flex;font-size:13px;font-size:.8125rem;outline:0;padding:2px 12px;text-overflow:ellipsis;-webkit-transition:background-color .3s cubic-bezier(.4,0,.2,1) 0s,-webkit-box-shadow .3s cubic-bezier(.4,0,.2,1) 0s;transition:background-color .3s cubic-bezier(.4,0,.2,1) 0s,-webkit-box-shadow .3s cubic-bezier(.4,0,.2,1) 0s;transition:background-color .3s cubic-bezier(.4,0,.2,1) 0s,box-shadow .3s cubic-bezier(.4,0,.2,1) 0s;transition:background-color .3s cubic-bezier(.4,0,.2,1) 0s,box-shadow .3s cubic-bezier(.4,0,.2,1) 0s,-webkit-box-shadow .3s cubic-bezier(.4,0,.2,1) 0s;white-space:nowrap;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;background-color:#e0e0e0;border-radius:16px;justify-content:center;text-decoration:none;vertical-align:middle}.M75eR{background:var(--success-background-color);color:var(--success-color)}.soZEe{background:var(--default-background-color);color:var(--default-color)}.os1QC{background:var(--warning-background-color);color:var(--warning-color)}.icLP_{background:var(--danger-background-color);color:var(--danger-color)}.NoIkT{border-radius:6px;font-size:11.2px;font-size:.7rem;height:19.2px;height:1.2rem;padding:0 6px}.DNSRR{font-size:13px;font-size:.8125rem;padding:.2rem 12px}.ClAsl{font-size:18px;font-size:1.125rem;padding:8px 12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `kZC0Y`,
	"success": `M75eR`,
	"primary": `soZEe`,
	"warning": `os1QC`,
	"danger": `icLP_`,
	"small": `NoIkT`,
	"medium": `DNSRR`,
	"large": `ClAsl`
};
module.exports = ___CSS_LOADER_EXPORT___;
