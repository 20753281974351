import { Currency } from 'types/emoney/Token';

export default (): Currency[] => {
  const all: Currency[] = [
    {
      code: 'EUR',
      name: 'Euro',
      format: 'en-US',
      sign: '€',
    },
    {
      code: 'GBP',
      name: 'Pound Sterling',
      format: 'en-US',
      sign: '£',
    },
    {
      code: 'ISK',
      name: 'Icelandic Krona',
      format: 'en-US',
      sign: 'kr',
    },
    {
      code: 'USD',
      name: 'US Dollar',
      format: 'en-US',
      sign: '$',
    },
  ];
  return all;
};
