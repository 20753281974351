import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import CopyButton from 'components/Button/Copy/CopyButton';
import Chip from 'components/Chip/Chip';
import { TreasuryAccount } from '../types';
import { shortenIban } from '../util';
import s from './Display.less';

// I'm not sure this belongs under Account/Sign ?
const Display = ({ account }: { account: TreasuryAccount }) => {
  useStyles(s);

  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 0,
  });
  if (account?.meta.state === 'approved') {
    return (
      <div ref={ref} className={s.identifierContainer}>
        <div className={s.accountDetails}>
          {account.identifier?.standard === 'iban' &&
          account.identifier?.iban ? (
            <>
              <span className={s.badge}>IBAN</span>
              <span
                className={cx(s.identifier, s[account.identifier?.standard])}
              >
                {width && width < 262
                  ? shortenIban(account.identifier.iban)
                  : account.identifier.iban}
              </span>
            </>
          ) : (
            account.identifier?.standard === 'scan' &&
            account?.identifier?.sortCode &&
            account?.identifier?.accountNumber && (
              <div className={s.row}>
                <span className={s.badge}>SORT</span>
                <span className={s.identifier}>
                  {account.identifier.sortCode}
                </span>

                {width && width >= 262 && <span className={s.badge}>NO.</span>}
                <span className={s.identifier}>
                  {account.identifier.accountNumber}
                </span>
              </div>
            )
          )}
          <CopyButton
            className={s.copyButton}
            value={
              account.identifier?.iban
                ? account.identifier.iban
                : `${account.identifier?.sortCode} ${account.identifier?.accountNumber}`
            }
          />
        </div>
      </div>
    );
  }
  if (account?.meta.state === 'requested') {
    return (
      <Chip size="small" color="primary">
        IBAN requested
      </Chip>
    );
  }
  return null;
};

export default Display;
