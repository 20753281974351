import React from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { ChainName } from 'src/types/emoney/Chain/indexV2';
import s from './Icon.less';

export function getSrc(chainName: ChainName, backgroundFill: boolean) {
  return `/assets/emoney/chains/${chainName}-${
    backgroundFill ? 'bgfill-' : ''
  }icon.svg`;
}

const Icon = ({
  chainName,
  className = '',
  backgroundFill = false,
}: {
  chainName: ChainName | 'Unknown';
  className?: string;
  backgroundFill?: boolean;
}) => {
  useStyles(s);
  return chainName ? (
    <img
      className={cx(className, s.image)}
      src={getSrc(chainName as ChainName, backgroundFill)}
      alt=""
    />
  ) : null;
};

export default Icon;
