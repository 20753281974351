import React, { FunctionComponent, useState } from 'react';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import copy from 'copy-to-clipboard';
import Button from '../Button';
import s from './CopyButton.less';

const CopyButton: FunctionComponent<{
  value: string;
  [k: string]: unknown;
}> = ({ value, ...other }) => {
  useStyles(s);
  const [isSuccess, setSuccess] = useState(false);
  return (
    <Button
      small
      icon="content_copy"
      success={isSuccess}
      onClick={() => {
        if (copy(value)) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 1000);
        }
      }}
      className={s.root}
      {...other}
    >
      Copy
    </Button>
  );
};

export default CopyButton;
