import React, { createContext, FC, useContext, useMemo, useState } from 'react';

interface UseSettingsContext {
  settingsView: boolean;
  setSettingsView: (value: boolean) => void;
}

export const SettingsContext = createContext<UseSettingsContext>({
  settingsView: false,
  setSettingsView: () => {
    // do nothing
  },
});

export const useSettingsContext = () => useContext(SettingsContext);

export const SettingsProvider: FC = ({ children }) => {
  const [settingsView, setSettingsView] = useState(false);

  const contextValues = useMemo(
    () => ({
      settingsView,
      setSettingsView,
    }),
    [settingsView, setSettingsView],
  );

  return (
    <SettingsContext.Provider value={contextValues}>
      {children}
    </SettingsContext.Provider>
  );
};
