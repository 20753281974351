import cx from 'classnames';
import ChainIcon from 'components/Icons/ChainIcon';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { useEffect, useState } from 'react';
import CopyButton from 'src/components/Button/Copy/CopyButton';
import TokenChainIcon from 'src/components/Icons/TokenChainIcon';
import Switch from 'src/components/Switch';
import DisplayAccount from 'src/customer/components/treasury/Account/Sign/Display';
import { shortenAddress } from 'src/utils/address';
import type { Currency } from 'types/emoney/Token';
import cu from '../../Token/util';
import { useSettingsContext } from '../Settings/SettingsProvider';
import { useAccountUpdate } from '../hooks';
import { Account } from '../types';
import s from './Balance.less';

interface BalanceProps {
  account: Account;
  showIcon?: boolean;
  tokenIcon?: boolean;
}
const Balance = ({
  account,
  showIcon = true,
  tokenIcon = false,
}: BalanceProps) => {
  useStyles(s);
  const { updateAccount, isError } = useAccountUpdate();

  const [tempStatus, setTempStatus] = useState<boolean | undefined>(
    account?.isVisible,
  );

  useEffect(() => {
    if (isError) setTempStatus(account?.isVisible);
  }, [isError]);

  const { currency, balance } = account;
  const cData = cu.get(currency) as Currency;
  const cFormat = cu.formatter(currency);
  const { settingsView } = useSettingsContext();
  const treasuryAccount = account.treasuryAccounts?.[0];
  const shortAddress = shortenAddress(account.address);

  return (
    <div className={cx(s.root, { [s.settingsView]: settingsView })}>
      {showIcon && (
        <div className={s.icon}>
          {currency && !tokenIcon && (
            <ChainIcon
              className={s.icon}
              backgroundFill
              chainName={account.chain}
            />
          )}
          {currency && tokenIcon && (
            <TokenChainIcon size={34} account={account} />
          )}
        </div>
      )}
      <div className={s.info}>
        <div className={s.address}>
          <span>{shortAddress}</span>
          <CopyButton className={s.copy} value={account.address} />
        </div>

        {treasuryAccount && (
          <span className={s.iban}>
            <DisplayAccount account={treasuryAccount} />
          </span>
        )}
      </div>
      <div className={s.balance}>
        <div className={s.switch}>
          {settingsView && (
            <Switch
              id={account.id}
              defaultChecked={tempStatus}
              onChange={async (isChecked: boolean) => {
                setTempStatus((status) => !status);
                updateAccount({
                  id: account.id,
                  isVisible: !!isChecked,
                });
              }}
            />
          )}
        </div>
        {balance && (
          <span className={s.amount}>
            {cData?.sign}
            {cFormat.format(balance as number)}
          </span>
        )}
      </div>
    </div>
  );
};

export default Balance;
