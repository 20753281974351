/* eslint-disable css-modules/no-unused-class */
import React, { FunctionComponent, HTMLAttributes } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Chip.less';

const Chip: FunctionComponent<
  HTMLAttributes<HTMLDivElement> & {
    label?: React.ReactNode;
    color?: 'default' | 'success' | 'warning' | 'danger' | 'primary';
    size?: 'small' | 'medium' | 'large';
  }
> = ({
  label,
  color = 'default',
  size = 'medium',
  children,
  className,
  ...other
}) => {
  useStyles(s);
  return (
    <div
      className={cx(
        s.root,
        s[size],
        { [className as string]: !!className },
        {
          [s.success]: color === 'success',
          [s.warning]: color === 'warning',
          [s.danger]: color === 'danger',
          [s.primary]: color === 'primary',
        },
      )}
      {...other}
    >
      {label}
      {children}
    </div>
  );
};

export default Chip;
