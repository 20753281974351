import ChainIcon from 'components/Icons/ChainIcon';
import TokenIcon from 'components/Icons/TokenIcon';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { declareCssVar } from 'src/utils/styles';
import { Account } from 'types/emoney/Account';
import s from './TokenChainIcon.less';

const TokenChainIcon = ({
  account,
  size = 42,
}: {
  account: Account;
  size?: number;
}) => {
  useStyles(s);
  if (account) {
    return (
      <div
        role="img"
        aria-label={`${account.currency} on ${account.chain}`}
        className={s.root}
        style={declareCssVar('--size', `${size}px`)}
      >
        <TokenIcon className={s.token} currencyCode={account.currency} />
        <ChainIcon
          className={s.chain}
          backgroundFill
          chainName={account.chain}
        />
      </div>
    );
  }
  return null;
};
export default TokenChainIcon;
