import React, { FunctionComponent } from 'react';
import { CurrencyCode } from 'types/emoney/Token';
import eurIcon from 'src/assets/emoney/tokens/eur.svg';
import gbpIcon from 'src/assets/emoney/tokens/gbp.svg';
import iskIcon from 'src/assets/emoney/tokens/isk.svg';
import usdIcon from 'src/assets/emoney/tokens/usd.svg';

const Icon: FunctionComponent<{
  currencyCode?: Lowercase<CurrencyCode> | Uppercase<CurrencyCode>;
  [key: string]: unknown;
}> = ({ currencyCode = '', ...otherProps }) => {
  let i = eurIcon;
  switch (currencyCode.toLowerCase()) {
    case 'gbp':
      i = gbpIcon;
      break;
    case 'eur':
      i = eurIcon;
      break;
    case 'usd':
      i = usdIcon;
      break;
    case 'isk':
      i = iskIcon;
      break;
    default:
      i = eurIcon;
      break;
  }

  return <img alt={`${currencyCode.toLowerCase()}`} src={i} {...otherProps} />;
};

export default Icon;
