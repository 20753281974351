import React, { FC, useState } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import * as RadixSwitch from '@radix-ui/react-switch';
import s from './Switch.less';

const Switch: FC<
  {
    className?: string;
    onChange?: (isChecked: boolean) => void;
    defaultChecked?: boolean;
    checked?: boolean;
  } & Omit<RadixSwitch.SwitchProps, 'onChange'>
> = ({
  checked,
  defaultChecked = false,
  className = '',
  onChange,
  ...otherProps
}) => {
  const [ripple, setRipple] = useState(false);
  useStyles(s);

  return (
    <RadixSwitch.Root
      checked={checked || defaultChecked}
      defaultChecked={defaultChecked}
      className={cx(s.root, className)}
      onCheckedChange={(isChecked) => {
        setRipple(isChecked);
        if (onChange) onChange(isChecked);
      }}
      {...otherProps}
    >
      <RadixSwitch.Thumb className={cx(s.thumb, { [s.ripple]: ripple })} />
    </RadixSwitch.Root>
  );
};

export default Switch;
