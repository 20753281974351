// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--breakpoint-xs:320px;--breakpoint-sm:574px;--breakpoint-md:767px;--breakpoint-lg:990px;--breakpoint-xl:1199px;--breakpoint-max-height:600px}.r6U5a{width:100%}.jkz0i,.r6U5a{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.jkz0i{-ms-flex-positive:1;flex-grow:1}._bQsp{font-weight:700;line-height:18px;margin-right:5px}.nwcQJ{height:18px;margin:0;width:20px}.nwcQJ i{font-size:16px!important;font-size:1rem!important}.sCWr8{-ms-flex-wrap:nowrap;flex-wrap:nowrap;margin-right:5px;white-space:nowrap}.sCWr8,.iQ1ea{display:-ms-flexbox;display:flex}.iQ1ea{line-height:18px;-ms-flex-align:center;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"identifierContainer": `r6U5a`,
	"accountDetails": `jkz0i`,
	"badge": `_bQsp`,
	"copyButton": `nwcQJ`,
	"identifier": `sCWr8`,
	"row": `iQ1ea`
};
module.exports = ___CSS_LOADER_EXPORT___;
